const routes = [
  {
    name: 'Dashboards',
    path: '/ehs',
    icon: 'fa-light fa-chart-line-up',
    matchPath: ['/ehs/dashboards'],
    completed: true,
    released: true
  },
  {
    name: 'EHS Modules',
    icon: 'fa-light fa-square-plus',
    matchPath: [],
    completed: true,
    released: true,
    children: [
      {
        name: 'Inspection Web Module',
        path: '/ehs/modules/inspection-web-module',
        matchPath: ['ehs/modules/inspection-web-module'],
        completed: true,
        released: true
      },
      {
        name: 'Incidents Web Module',
        path: '/ehs/modules/incidents-web-module',
        matchPath: ['ehs/modules/incidents-web-module'],
        completed: true,
        released: true
      },
      {
        name: 'Observations Web Module',
        path: '/ehs/modules/observations-web-module',
        matchPath: ['ehs/modules/observations-web-module'],
        completed: true,
        released: true
      },
      {
        name: 'JSA Web Module',
        path: '/ehs/modules/jsa-web-module',
        matchPath: ['ehs/modules/jsa-web-module'],
        completed: true,
        released: true
      },
      {
        name: 'Safety Scan Web Module',
        path: '/ehs/modules/safety-scan-web-module',
        matchPath: ['ehs/modules/safety-scan-web-module'],
        completed: true,
        released: true
      },
      {
        name: 'Forms Web Module',
        path: '/ehs/modules/forms-web-module',
        matchPath: ['ehs/modules/forms-web-module'],
        completed: true,
        released: true
      }
    ]
  },
  {
    name: 'Inspections',
    icon: 'fa-light fa-clipboard-list-check',
    matchPath: [],
    guard: ['InspectionsView'],
    completed: true,
    released: true,
    children: [
      {
        name: 'Inspection Maintenance',
        path: '/ehs/inspections/inspection-maintenance',
        matchPath: ['ehs/inspections/inspection-maintenance'],
        guard: ['InspectionMaintenanceView'],
        completed: true,
        released: true
      },
      {
        name: 'Job Bank Maintenance',
        path: '/ehs/inspections/job-bank-maintenance',
        matchPath: ['ehs/inspections/job-bank-maintenance'],
        guard: ['JobBankMaintenanceView'],
        completed: true,
        released: true
      },
      {
        name: 'Standard Checklist Maintenance',
        path: '/ehs/inspections/standard-checklist-maintenance',
        matchPath: ['ehs/inspections/standard-checklist-maintenance'],
        guard: ['StandardChecklistMaintenanceView'],
        completed: true,
        released: true
      },
      {
        name: 'Custom Checklist Maintenance',
        path: '/ehs/inspections/custom-checklist-maintenance',
        matchPath: ['ehs/inspections/custom-checklist-maintenance'],
        guard: ['CustomChecklistMaintenanceView'],
        completed: true,
        released: true
      },
      {
        name: 'Corrective Action Tracking',
        path: '/ehs/inspections/corrective-action-tracking',
        matchPath: ['ehs/inspections/corrective-action-tracking'],
        guard: ['CorrectiveActionTrackingView'],
        completed: true,
        released: true
      },
      {
        name: 'Remote Sessions',
        path: '/ehs/inspections/remote-sessions',
        matchPath: ['ehs/inspections/remote-sessions'],
        guard: ['RemoteSessionsView'],
        // entitlement: 'Entitlement_Safety_Tier_VSR_Sessions',
        completed: true,
        released: true
      },
      {
        name: 'Inspection Settings',
        path: '/ehs/inspections/inspection-settings',
        matchPath: ['ehs/inspections/inspection-settings'],
        guard: ['InspectionSettingsView'],
        completed: true,
        released: true
      }
    ]
  },
  {
    name: 'Training',
    icon: 'fa-light fa-person-chalkboard',
    matchPath: [],
    guard: ['TrainingView'],
    completed: true,
    released: true,
    children: [
      {
        name: 'Training Events',
        path: '/ehs/training/training-events',
        matchPath: ['ehs/training/training-events'],
        guard: ['TrainingEventsView'],
        completed: true,
        released: true
      },
      {
        name: 'Training Tracking',
        path: '/ehs/training/training-tracking',
        matchPath: ['ehs/training/training-tracking'],
        guard: ['TrainingTrackingView'],
        completed: true,
        released: true
      },
      {
        name: 'ToolBoxTalks',
        path: '/ehs/training/tool-box-talks',
        matchPath: ['ehs/training/tool-box-talks'],
        guard: ['ToolBoxTalksView'],
        completed: true,
        released: true
      },
      {
        name: 'Training History',
        path: '/ehs/training/training-history',
        matchPath: ['ehs/training/training-history'],
        guard: ['TrainingHistoryView'],
        completed: true,
        released: true
      },
      {
        name: 'Required Trainings',
        path: '/ehs/training/required-trainings',
        matchPath: ['ehs/training/required-trainings'],
        guard: ['RequiredTrainingsView'],
        completed: true,
        released: true
      },
      {
        name: 'Training Exam',
        path: '/ehs/training/training-exam',
        matchPath: ['ehs/training/training-exam'],
        guard: ['TrainingExamView'],
        // entitlement: 'Entitlement_Safety_Tier_Training_Exams',
        completed: true,
        released: true
      },
      {
        name: 'Training Confirmation',
        path: '/ehs/training/training-confirmation',
        matchPath: ['ehs/training/training-confirmation'],
        guard: ['TrainingConfirmationView'],
        completed: true,
        released: true
      },
      {
        name: 'Training Settings',
        path: '/ehs/training/training-settings',
        matchPath: ['ehs/training/training-settings'],
        guard: ['TrainingSettingsView'],
        completed: true,
        released: true
      }
    ]
  },
  {
    name: 'Incidents',
    icon: 'fa-light fa-triangle-exclamation',
    matchPath: [],
    guard: ['IncidentsView'],
    completed: true,
    released: true,
    children: [
      {
        name: 'Incident Maintenance',
        path: '/ehs/incidents/incident-maintenance',
        matchPath: ['ehs/incidents/incident-maintenance'],
        guard: ['IncidentMaintenanceView'],
        completed: true,
        released: true
      },
      {
        name: 'OSHA 301',
        path: '/ehs/incidents/osha-301',
        matchPath: ['ehs/incidents/osha-301'],
        guard: ['OSHA301View'],
        completed: true,
        released: true
      },
      {
        name: 'OSHA 300 Log',
        path: '/ehs/incidents/osha-300-log',
        matchPath: ['ehs/incidents/osha-300-log'],
        guard: ['OSHA300LogView'],
        completed: true,
        released: true
      },
      {
        name: 'Behavior Maintenance',
        path: '/ehs/incidents/behavior-maintenance',
        matchPath: ['ehs/incidents/behavior-maintenance'],
        guard: ['BehaviorMaintenanceView'],
        completed: true,
        released: true
      },
      {
        name: 'Conditions Maintenance',
        path: '/ehs/incidents/conditions-maintenance',
        matchPath: ['ehs/incidents/conditions-maintenance'],
        guard: ['ConditionsMaintenanceView'],
        completed: true,
        released: true
      },
      {
        name: 'Entity Maintenance',
        path: '/ehs/incidents/entity-maintenance',
        matchPath: ['ehs/incidents/entity-maintenance'],
        guard: ['EntityMaintenanceView'],
        completed: true,
        released: true
      },
      {
        name: 'Incident Settings',
        path: '/ehs/incidents/incident-settings',
        matchPath: ['ehs/incidents/incident-settings'],
        guard: ['IncidentSettingsView'],
        completed: true,
        released: true
      }
    ]
  },
  {
    name: 'Observations',
    icon: 'fa-light fa-user-magnifying-glass',
    matchPath: [],
    guard: ['ObservationsView'],
    completed: true,
    released: true,
    children: [
      {
        name: 'Observation Maintenance',
        path: '/ehs/observations/observation-maintenance',
        matchPath: ['ehs/observations/observation-maintenance'],
        guard: ['ObservationMaintenanceView'],
        completed: true,
        released: true
      },
      {
        name: 'Category Maintenance',
        path: '/ehs/observations/category-maintenance',
        matchPath: ['ehs/observations/category-maintenance'],
        guard: ['CategoryMaintenanceView'],
        completed: true,
        released: true
      },
      {
        name: 'Contributing Factor Maintenance',
        path: '/ehs/observations/contributing-factor-maintenance',
        matchPath: ['ehs/observations/contributing-factor-maintenance'],
        guard: ['ContributingFactorMaintenanceView'],
        completed: true,
        released: true
      },
      {
        name: 'Observation Settings',
        path: '/ehs/observations/observation-settings',
        matchPath: ['ehs/observations/observation-settings'],
        guard: ['ObservationSettingsView'],
        completed: true,
        released: true
      }
    ]
  },
  {
    name: 'Job Safety Analysis',
    icon: 'fa-light fa-list-tree',
    matchPath: [],
    guard: ['JobSafetyAnalysisView'],
    completed: true,
    released: true,
    children: [
      {
        name: 'JSA Maintenance',
        path: '/ehs/job-safety-analysis/jsa-maintenance',
        matchPath: ['ehs/job-safety-analysis/jsa-maintenance'],
        guard: ['JSAMaintenanceView'],
        // entitlement: 'Entitlement_Safety_Tier_JSA_Users',
        completed: true,
        released: true
      },
      {
        name: 'JSA Bank Maintenance',
        path: '/ehs/job-safety-analysis/jsa-bank-maintenance',
        matchPath: ['ehs/job-safety-analysis/jsa-bank-maintenance'],
        guard: ['JSABankMaintenanceView'],
        // entitlement: 'Entitlement_Safety_Tier_JSA_Users',
        completed: true,
        released: true
      },
      {
        name: 'Hazards Maintenance',
        path: '/ehs/job-safety-analysis/hazards-maintenance',
        matchPath: ['ehs/job-safety-analysis/hazards-maintenance'],
        guard: ['HazardsMaintenanceView'],
        // entitlement: 'Entitlement_Safety_Tier_JSA_Users',
        completed: true,
        released: true
      },
      {
        name: 'Controls Maintenance',
        path: '/ehs/job-safety-analysis/controls-maintenance',
        matchPath: ['ehs/job-safety-analysis/controls-maintenance'],
        guard: ['ControlsMaintenanceView'],
        // entitlement: 'Entitlement_Safety_Tier_JSA_Users',
        completed: true,
        released: true
      },
      {
        name: 'Classification Maintenance',
        path: '/ehs/job-safety-analysis/classification-maintenance',
        matchPath: ['ehs/job-safety-analysis/classification-maintenance'],
        guard: ['ClassificationMaintenanceView'],
        // entitlement: 'Entitlement_Safety_Tier_JSA_Users',
        completed: true,
        released: true
      },
      {
        name: 'PPE Maintenance',
        path: '/ehs/job-safety-analysis/ppe-maintenance',
        matchPath: ['ehs/job-safety-analysis/ppe-maintenance'],
        guard: ['PPEMaintenanceView'],
        // entitlement: 'Entitlement_Safety_Tier_JSA_Users',
        completed: true,
        released: true
      },
      {
        name: 'Required Training Maintenance',
        path: '/ehs/job-safety-analysis/required-training-maintenance',
        matchPath: ['ehs/job-safety-analysis/required-training-maintenance'],
        guard: ['RequiredTrainingMaintenanceView'],
        // entitlement: 'Entitlement_Safety_Tier_JSA_Users',
        completed: true,
        released: true
      },
      {
        name: 'Chemical Maintenance',
        path: '/ehs/job-safety-analysis/chemical-maintenance',
        matchPath: ['ehs/job-safety-analysis/chemical-maintenance'],
        guard: ['ChemicalMaintenanceView'],
        // entitlement: 'Entitlement_Safety_Tier_JSA_Users',
        completed: true,
        released: true
      },
      {
        name: 'JSA Settings',
        path: '/ehs/job-safety-analysis/jsa-settings',
        matchPath: ['ehs/job-safety-analysis/jsa-settings'],
        guard: ['JSASettingsView'],
        // entitlement: 'Entitlement_Safety_Tier_JSA_Users',
        completed: true,
        released: true
      }
    ]
  },
  {
    name: 'Safety Scan',
    icon: 'fa-light fa-qrcode',
    matchPath: [],
    guard: ['SafetyScanView'],
    completed: true,
    released: true,
    children: [
      {
        name: 'Assets',
        path: '/ehs/safety-scan/assets',
        matchPath: ['/ehs/safety-scan/assets'],
        guard: ['AssetsView'],
        exact: true,
        // entitlement: 'Entitlement_Safety_Tier_Scan_Assets',
        completed: true,
        released: true
      },
      {
        name: 'Asset Types',
        path: '/ehs/safety-scan/assets-types',
        matchPath: ['ehs/safety-scan/assets-types'],
        guard: ['AssetTypesView'],
        // entitlement: 'Entitlement_Safety_Tier_Scan_Assets',
        completed: true,
        released: true
      },
      {
        name: 'Checklists',
        path: '/ehs/safety-scan/checklists',
        matchPath: ['ehs/safety-scan/checklists'],
        guard: ['ChecklistsView'],
        // entitlement: 'Entitlement_Safety_Tier_Scan_Assets',
        completed: true,
        released: true
      },
      {
        name: 'Generate QR Codes',
        path: '/ehs/safety-scan/generate-qr-codess',
        matchPath: ['ehs/safety-scan/generate-qr-codes'],
        guard: ['GenerateQRCodesView'],
        // entitlement: 'Entitlement_Safety_Tier_Scan_Assets',
        completed: true,
        released: true
      },
      {
        name: 'Overview',
        path: '/ehs/safety-scan/overview',
        matchPath: ['ehs/safety-scan/overview'],
        guard: ['OverviewView'],
        // entitlement: 'Entitlement_Safety_Tier_Scan_Assets',
        completed: true,
        released: true
      },
      {
        name: 'Corrective Action Tracking',
        route_name: 'Corrective Action Tracking-Safety Scan',
        path: '/ehs/safety-scan/corrective-action-tracking',
        matchPath: ['ehs/safety-scan/corrective-action-tracking'],
        guard: ['ScanCorrectiveActionTrackingView'],
        // entitlement: 'Entitlement_Safety_Tier_Scan_Assets',
        completed: true,
        released: true
      },
      {
        name: 'Routes',
        path: '/ehs/safety-scan/routes',
        matchPath: ['ehs/safety-scan/routes'],
        guard: ['RoutesView'],
        // entitlement: 'Entitlement_Safety_Tier_Scan_Assets',
        completed: true,
        released: true
      },
      {
        name: 'Locations',
        route_name: 'Locations-Safety Scan',
        path: '/ehs/safety-scan/locations',
        matchPath: ['ehs/safety-scan/locations'],
        guard: ['EHSLocationsView'],
        // entitlement: 'Entitlement_Safety_Tier_Scan_Assets',
        completed: true,
        released: true
      },
      {
        name: 'Building, Floor & Area',
        path: '/ehs/safety-scan/building-floor-area',
        matchPath: ['ehs/safety-scan/building-floor-area'],
        guard: ['Building,Floor&AreaView'],
        // entitlement: 'Entitlement_Safety_Tier_Scan_Assets',
        completed: true,
        released: true
      },
      {
        name: 'Zone',
        path: '/ehs/safety-scan/zone',
        matchPath: ['ehs/safety-scan/zone'],
        guard: ['ZoneView'],
        // entitlement: 'Entitlement_Safety_Tier_Scan_Assets',
        completed: true,
        released: true
      },
      {
        name: 'Division',
        path: '/ehs/safety-scan/division',
        matchPath: ['ehs/safety-scan/division'],
        guard: ['DivisionView'],
        // entitlement: 'Entitlement_Safety_Tier_Scan_Assets',
        completed: true,
        released: true
      },
      {
        name: 'Safety Scan Settings',
        path: '/ehs/safety-scan/safety-scan-settings',
        matchPath: ['ehs/safety-scan/safety-scan-settings'],
        guard: ['SafetyScanSettingsView'],
        // entitlement: 'Entitlement_Safety_Tier_Scan_Assets',
        completed: true,
        released: true
      }
    ]
  },
  {
    name: 'Forms',
    icon: 'fa-light fa-memo-circle-check',
    matchPath: [],
    guard: ['FormsView'],
    completed: true,
    released: true,
    children: [
      {
        name: 'Forms Maintenance',
        path: '/ehs/forms/forms-maintenance',
        matchPath: ['ehs/forms/forms-maintenance'],
        guard: ['FormsMaintenanceView'],
        // entitlement: 'Entitlement_Safety_Tier_Forms_Users',
        completed: true,
        released: true
      },
      {
        name: 'Completed Forms',
        path: '/ehs/forms/completed-forms',
        matchPath: ['ehs/forms/completed-forms'],
        guard: ['CompletedFormsView'],
        // entitlement: 'Entitlement_Safety_Tier_Forms_Users',
        completed: true,
        released: true
      },
      {
        name: 'Corrective Action',
        path: '/ehs/forms/corrective-action',
        matchPath: ['ehs/forms/corrective-action'],
        guard: ['CorrectiveActionTrackingView'],
        // entitlement: 'Entitlement_Safety_Tier_Forms_Users',
        completed: true,
        released: true
      },
      {
        name: 'Forms Settings',
        path: '/ehs/forms/forms-settings',
        matchPath: ['ehs/forms/forms-settings'],
        guard: ['FormsSettingsView'],
        // entitlement: 'Entitlement_Safety_Tier_Forms_Users',
        completed: true,
        released: true
      }
    ]
  },
  {
    name: 'Reports & Graphs',
    icon: 'fa-light fa-file-chart-pie',
    matchPath: [],
    guard: ['EHSReports&GraphsView'],
    completed: true,
    released: true,
    children: [
      {
        name: 'Performance Reports',
        route_name: 'Inspection Performance Reports',
        path: '/ehs/reports-graphs/performance-reports/inspection-performance-reports',
        matchPath: ['ehs/reports-graphs/performance-reports/'],
        guard: ['PerformanceReportsView'],
        completed: true,
        released: true
      },
      {
        name: 'Scheduled Reports',
        route_name: 'Inspection Scheduled Reports',
        path: '/ehs/reports-graphs/scheduled-reports/inspection-scheduled-reports',
        matchPath: ['ehs/reports-graphs/scheduled-reports/'],
        guard: ['EHSScheduledReportsView'],
        completed: true,
        released: true
      }
    ]
  },
  {
    name: 'Administration',
    icon: 'fa-light fa-user-gear',
    matchPath: [],
    completed: true,
    released: true,
    children: [
      {
        name: 'Account Settings',
        path: '/ehs/administration/account-settings',
        matchPath: ['ehs/administration/account-settings'],
        guard: ['AccountSettingsView'],
        completed: true,
        released: true
      },
      {
        name: 'Billing Information',
        path: '/ehs/administration/billing-information',
        matchPath: ['ehs/administration/billing-information'],
        guard: ['BillingInformationView'],
        completed: true,
        released: true
      },
      {
        name: 'Configure User Roles',
        route_name: 'Configure User Roles-EHS',
        path: 'ehs/administration/configure-user-roles',
        matchPath: ['ehs/administration/configure-user-roles'],
        guard: ['RolesView'],
        completed: true,
        released: true
      },
      {
        name: 'Document Central',
        route_name: 'Document Central-EHS',
        path: 'ehs/administration/document-central',
        matchPath: ['ehs/administration/document-central'],
        guard: ['DocumentCentralView'],
        completed: true,
        released: true
      },
      {
        name: 'Site Safety Plans',
        route_name: 'Site Safety Plans-EHS',
        path: 'ehs/administration/safety-plan',
        matchPath: ['ehs/administration/safety-plan'],
        guard: ['SiteSafetyPlansView'],
        completed: true,
        released: true
      },
      {
        name: 'Users',
        route_name: 'Users-EHS',
        path: '/ehs/administration/users',
        matchPath: ['ehs/administration/users'],
        guard: ['SiteSafetyPlansView'],
        completed: true,
        released: true
      },
      {
        name: 'Integration',
        matchPath: [],
        completed: true,
        released: true,
        children: [
          {
            name: 'Procore Integration',
            path: '/ehs/integration/procore-integration',
            matchPath: ['ehs/integration/procore-integration'],
            guard: ['ProcoreIntegrationView'],
            // entitlement: 'Entitlement_Safety_Tier_Integrations',
            completed: true,
            released: true
          },
          {
            name: 'Plangrid Integration',
            path: '/ehs/integration/plangrid-integration',
            matchPath: ['ehs/integration/plangrid-integration'],
            guard: ['PlangridIntegrationView'],
            // entitlement: 'Entitlement_Safety_Tier_Integrations',
            completed: true,
            released: true
          }
        ]
      },
      {
        name: 'Company Lists',
        matchPath: [],
        guard: ['ListMaintenanceView'],
        completed: true,
        released: true,
        children: [
          {
            name: 'Certifications',
            route_name: 'Certifications-EHS',
            guard: ['CertificationsView'],
            path: '/ehs/company-lists/certifications',
            matchPath: ['ehs/company-lists/certifications'],
            // entitlement: 'T_And_E',
            completed: true,
            released: true
          },
          {
            name: 'Contractors',
            route_name: 'Contractors-EHS',
            guard: ['ContractorsView'],
            path: '/ehs/company-lists/contractors',
            matchPath: ['ehs/company-lists/contractors'],
            // entitlement: 'T_And_E',
            completed: true,
            released: true
          },
          {
            name: 'Customers',
            route_name: 'Customers-EHS',
            guard: ['CustomersView'],
            path: '/ehs/company-lists/customers',
            matchPath: ['ehs/company-lists/customers'],
            // entitlement: 'T_And_E',
            completed: true,
            released: true
          },
          {
            name: 'Departments',
            route_name: 'Departments-EHS',
            guard: ['DepartmentsView'],
            path: '/ehs/company-lists/departments',
            matchPath: ['ehs/company-lists/departments'],
            // entitlement: 'T_And_E',
            completed: true,
            released: true
          },
          {
            name: 'Descriptions',
            route_name: 'Descriptions-EHS',
            guard: ['DescriptionsView'],
            path: '/ehs/company-lists/descriptions',
            matchPath: ['ehs/company-lists/descriptions'],
            // entitlement: 'T_And_E',
            completed: true,
            released: true
          },
          {
            name: 'Employees',
            route_name: 'Employees-EHS',
            guard: ['EmployeesView'],
            path: '/ehs/company-lists/employees',
            matchPath: ['ehs/company-lists/employees'],
            // entitlement: 'T_And_E',
            completed: true,
            released: true
          },
          {
            name: 'Employee Types',
            route_name: 'Employee Types-EHS',
            guard: ['EmployeeTypesView'],
            path: '/ehs/company-lists/employee-types',
            matchPath: ['ehs/company-lists/employee-types'],
            // entitlement: 'T_And_E',
            completed: true,
            released: true
          },
          {
            name: 'Locations',
            route_name: 'Locations-EHS',
            guard: ['LocationsView'],
            path: '/ehs/company-lists/locations',
            matchPath: ['ehs/company-lists/locations'],
            // entitlement: 'T_And_E',
            completed: true,
            released: true
          },
          {
            name: 'Manufacturers',
            route_name: 'Manufacturers-EHS',
            guard: ['ManufacturersView'],
            path: '/ehs/company-lists/manufacturers',
            matchPath: ['ehs/company-lists/manufacturers'],
            // entitlement: 'T_And_E',
            completed: true,
            released: true
          }
        ]
      },
      {
        name: 'EHS Settings',
        icon: 'fa-light fa-circle-question',
        matchPath: [],
        guard: ['EHSSettingsView'],
        completed: true,
        released: true,
        children: [
          {
            name: 'EHS License Maintenance',
            route_name: 'Inspection License Maintenance',
            path: '/ehs/ehs-settings/ehs-license-maintenance/inspection-license-maintenance',
            matchPath: ['ehs/ehs-settings/ehs-license-maintenance'],
            guard: ['EHSLicenseMaintenanceView'],
            completed: true,
            released: true
          },
          {
            name: 'Company Maintenance',
            path: '/ehs/ehs-settings/company-maintenance/certifications',
            matchPath: ['ehs/ehs-settings/company-maintenance'],
            guard: ['CompanyMaintenanceView'],
            completed: true,
            released: true
          },
          {
            name: 'Location Maintenance',
            route_name: 'Observation Location Maintenance',
            path: '/ehs/ehs-settings/location-maintenance/observation-location-maintenance',
            matchPath: ['ehs/ehs-settings/location-maintenance'],
            guard: ['LocationMaintenanceView'],
            completed: true,
            released: true
          },
          {
            name: 'Responsible Party Maintenance',
            route_name: 'Inspection RP Maintenance',
            path: '/ehs/ehs-settings/responsible-party-maintenance/inspection-pr-maintenance',
            matchPath: ['ehs/ehs-settings/responsible-party-maintenance'],
            guard: ['ResponsiblePartyMaintenanceView'],
            completed: true,
            released: true
          },
          {
            name: 'Data Export / API',
            route_name: 'Inspection Data Export / API',
            path: '/ehs/ehs-settings/data-export-api/inspection-data-export-api',
            matchPath: ['ehs/ehs-settings/data-export-api'],
            guard: ['DataExport/APIView'],
            completed: true,
            released: true
          }
        ]
      }
    ]
  },
  {
    name: 'Documentation',
    icon: 'fa-light fa-file-circle-question',
    matchPath: ['Documentation'],
    completed: true,
    released: true
  },
  {
    name: 'Get Support',
    icon: 'fa-light fa-circle-question',
    matchPath: ['getSupport'],
    completed: true,
    released: true,
    jobSiteUser: true,
    wareHouseUser: true,
    backOfficeUser: true
  }
]

export default routes
